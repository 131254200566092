<template>
  <div class="font-montserrat top">
    <div class="row py-24 d-flex justify-content-center px-12 oonpay-bg">
      <div class="col-md-8">
        <h4 class="font-semibold text-center text-oonpaytext text-3xl">
          HealthHub Categories
        </h4>
      </div>
    </div>

    <!-- <spinner v-if="loading" /> -->

    <div v-if="loading" class="row py-5 justify-content-center">
      <fade-loader :loading="loading" :color="color"></fade-loader>
    </div>

    <div v-else class="container">
      <div class="row justify-content-center pt-5">
        <div class="col-md-5">
          <div class="form-group">
            <label for="">Change Super Category</label>
            <select
              @change="handleSubcategory"
              name=""
              class="form-control"
              v-model="id"
            >
              <option
                v-for="item in supercategories"
                :key="item.id"
                :selected="id === item.id ? 'selected' : null"
                :value="item.id"
                >{{ item.fullName }}</option
              >
            </select>
          </div>
        </div>
      </div>
      <div class="row px-10 py-5 ">
        <div class="col-md-3" v-for="item in categories" :key="item.id">
          <div @click="SelectPack(item.id,item.imageUrl)" class="card">
            <img
              v-if="item.imageUrl"
              v-lazy="
                `${healthhubbaseUrl}/oonpayhealthhubsetup/${item.imageUrl}?cache=${uniqueParam}`
              "
              class="card-img-top fixed-size-image"
              alt="Product Image"
               @load="onLoad"
            />
            <div v-if="isLoading" class="spinner">
              <img src="@/assets/Spinner.gif" alt="Loading..." />
            </div>
            
            <div class="card-body text-center">
              <h5 class="card-title">
                {{
                  item.fullName.length > 25
                    ? item.fullName.slice(0, 20) + "..."
                    : item.fullName
                }}
              </h5>

              <!-- <h5 class="card-title">{{ item.fullName }}</h5> -->

              <button
                @click="SelectPack(item.id,item.imageUrl)"
                class="py-2 px-4 btn-primary text-white rounded hover:bg-blue-600 active:bg-blue-700 disabled:opacity-50 mt-4 w-full flex items-center justify-center"
              >
                Select Pack
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import { ImagebaseUrl } from "../../config";

export default {
  data() {
    return {
      healthhubbaseUrl: ImagebaseUrl,
      id: this.$route.params.id,
      showpacks: false,
      package_for: "",
      uniqueParam: Date.now(),
      loading: false,
      color: "#0b4369",
      imgurl: null,
      isLoading: true,
    };
  },

  watch: {
    id(newValue) {
      localStorage.setItem("paramsId", newValue);
    }
  },

  components: {
    FadeLoader
  },
  computed: {
    ...mapGetters("healthhubclient", ["supercategories", "categories"])
  },
  mounted() {
    this.getSubcategories();
    localStorage.setItem("paramsId", this.id);
  },
  methods: {
    onLoad() {
      this.isLoading = false;
    },
   
    getSubcategories() {
      this.loading = true;
      this.$store
        .dispatch("healthhubclient/getCategorie", this.id)
        .then((res) => {
          if (res.status == 200) {
            localStorage.setItem("imgurl", res.data.imageUrl);
            this.loading = false;
          }
        });
    },

    SelectPack(id,url) {
      this.$store.dispatch("healthhubclient/setCategoryId", id);
      localStorage.setItem("categoryId", id);
       localStorage.setItem("imgurl", url);
      this.$router.push(`/subcategory/${id}`);
    },

    handleSubcategory(e) {
      this.$store.dispatch("healthhubclient/getCategorie", e.target.value);
      this.$store
        .dispatch("healthhubclient/getHealthhubSupercategory")
        .then((res) => {
          const item = res.data.find((el) => el.id == e.target.value);
          this.imgurl = item.imageUrl;
          localStorage.setItem("imgurl", this.imgurl);
        });
      this.$router.replace({ params: { id: e.target.value } });
    }
  }
};
</script>

<style scoped>
.fixed-size-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.oonpay-bg {
  background: #0c4369;
}

a {
  color: #585858;
}

i {
  color: #d02d2c;
}

.bg-color {
  background: #f7f4fd;
}

.oonpay-bg {
  background: #0c4369;
}

.bg-color {
  background: #f7f4fd;
}

.oonpay-bg {
  background: #0c4369;
}

@media screen and (max-width: 720px) {
  .oonpay-row {
    flex-direction: column-reverse;
    margin: 5rem 0;
  }
  .top {
    margin-top: -80px;
  }
}
</style>
